.Head {
    background-image: url('../images/rn_head.png');
    height: 75vmin;
    width: 75vmin;
    background-size: cover;
    position: relative;
   
}
.Brain {
    position: absolute;
    height: 100%;
    width: 100%;
    clip-path: polygon(68.22% 6.37%, 81.8% 18.75%, 89.5% 32.59%, 93.66% 46.79%, 93.22% 62.53%, 90.97% 73.8%, 85.3% 87.35%, 76.62% 99.69%, 62.72% 97.31%, 45.48% 93.27%, 31.89% 85.2%, 27.64% 71.38%, 19.53% 62.11%, 6.48% 59.84%, 1.24% 50.07%, 4.09% 36.21%, 11.41% 19.4%, 23.01% 7.17%, 38.52% 1.89%, 54.88% 2.04%);
    background: black;
}
.BrainContainer {
  height: 42vmin;
  width: 55vmin;
  margin-top: 6vmin;
  margin-left: 15vmin;
  position: absolute;
}
.planet {
  background: url('../images/neptune-cropped-circle.png');
  position: absolute;
  height: 20%;
  width: 20%;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
}
.rollingNumbersPlanet {
  background: url('../images/RollingNumbersLogo.png');
  position: absolute;
  height: 20%;
  width: 14%;
  background-size: contain;
  background-repeat: no-repeat;
  right: 0;
  top: 70%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
}
.thoughtBubble {
  background: url('../images/thought-bubble.png');
  position: absolute;
  height: 20%;
  width: 18%;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 28%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  opacity: 0;
}
.marquee-text {
  animation: marquee 5s linear infinite;
  color: #ff7f00;
  letter-spacing: normal;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  margin-top: 34%;
  border:none;
  text-shadow: 
  
    0 6px 1px rgba(0,0,0,.1),
    0 0 5px rgba(0,0,0,.1),
    0 1px 3px rgba(0,0,0,.3),
    0 3px 5px rgba(0,0,0,.2),
    0 5px 10px rgba(0,0,0,.25),
    0 10px 10px rgba(0,0,0,.2),
    0 20px 20px rgba(0,0,0,.15);
}

.shadow-text {
  font-size: large;
  text-shadow: 
  
    0 6px 1px rgba(0,0,0,.1),
    0 0 5px rgba(0,0,0,.1),
    0 1px 3px rgba(0,0,0,.3),
    0 3px 5px rgba(0,0,0,.2),
    0 5px 10px rgba(0,0,0,.25),
    0 10px 10px rgba(0,0,0,.2),
    0 20px 20px rgba(0,0,0,.15);  
}

@keyframes marquee {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}