.item {
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  }
  
  #muse {
    background-image: url('../images/muse.jpg');
  }

  #isyimd {
    background-image: url('../images/ISYIMD.jpg');
  }

  #moy {
    background-image: url('../images/moy.jpg');
  }

  #everything {
    background-image: url('../images/Everything.png');
  }

  #brightside {
    background-image: url('../images/bright2.jpg');
  }

  #pcbh {
    background-image: url('../images/PCBH2.png');
  }

  #ata {
    background-image: url('../images/ata.jpg');
  }

  #highwaysigns {
    background-image: url('../images/highwaysigns.jpg');
  }

  #righton {
    background-image: url('../images/righton.jpg');
  }

  #imready {
    background-image: url('../images/imready.jpg');
  }

  #lazyriver {
    background-image: url('../images/lazyriver.jpg');
  }

  #procrastinator {
    background-image: url('../images/procrastinator2.png');
  }
