@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Head {
    background-image: url(/static/media/rn_head.8617bd08.png);
    height: 75vmin;
    width: 75vmin;
    background-size: cover;
    position: relative;
   
}
.Brain {
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-clip-path: polygon(68.22% 6.37%, 81.8% 18.75%, 89.5% 32.59%, 93.66% 46.79%, 93.22% 62.53%, 90.97% 73.8%, 85.3% 87.35%, 76.62% 99.69%, 62.72% 97.31%, 45.48% 93.27%, 31.89% 85.2%, 27.64% 71.38%, 19.53% 62.11%, 6.48% 59.84%, 1.24% 50.07%, 4.09% 36.21%, 11.41% 19.4%, 23.01% 7.17%, 38.52% 1.89%, 54.88% 2.04%);
            clip-path: polygon(68.22% 6.37%, 81.8% 18.75%, 89.5% 32.59%, 93.66% 46.79%, 93.22% 62.53%, 90.97% 73.8%, 85.3% 87.35%, 76.62% 99.69%, 62.72% 97.31%, 45.48% 93.27%, 31.89% 85.2%, 27.64% 71.38%, 19.53% 62.11%, 6.48% 59.84%, 1.24% 50.07%, 4.09% 36.21%, 11.41% 19.4%, 23.01% 7.17%, 38.52% 1.89%, 54.88% 2.04%);
    background: black;
}
.BrainContainer {
  height: 42vmin;
  width: 55vmin;
  margin-top: 6vmin;
  margin-left: 15vmin;
  position: absolute;
}
.planet {
  background: url(/static/media/neptune-cropped-circle.523fae80.png);
  position: absolute;
  height: 20%;
  width: 20%;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
}
.rollingNumbersPlanet {
  background: url(/static/media/RollingNumbersLogo.16acae05.png);
  position: absolute;
  height: 20%;
  width: 14%;
  background-size: contain;
  background-repeat: no-repeat;
  right: 0;
  top: 70%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
}
.thoughtBubble {
  background: url(/static/media/thought-bubble.c3303f67.png);
  position: absolute;
  height: 20%;
  width: 18%;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 28%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  opacity: 0;
}
.marquee-text {
  -webkit-animation: marquee 5s linear infinite;
          animation: marquee 5s linear infinite;
  color: #ff7f00;
  letter-spacing: normal;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  margin-top: 34%;
  border:none;
  text-shadow: 
  
    0 6px 1px rgba(0,0,0,.1),
    0 0 5px rgba(0,0,0,.1),
    0 1px 3px rgba(0,0,0,.3),
    0 3px 5px rgba(0,0,0,.2),
    0 5px 10px rgba(0,0,0,.25),
    0 10px 10px rgba(0,0,0,.2),
    0 20px 20px rgba(0,0,0,.15);
}

.shadow-text {
  font-size: large;
  text-shadow: 
  
    0 6px 1px rgba(0,0,0,.1),
    0 0 5px rgba(0,0,0,.1),
    0 1px 3px rgba(0,0,0,.3),
    0 3px 5px rgba(0,0,0,.2),
    0 5px 10px rgba(0,0,0,.25),
    0 10px 10px rgba(0,0,0,.2),
    0 20px 20px rgba(0,0,0,.15);  
}

@-webkit-keyframes marquee {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes marquee {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
.item {
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  }
  
  #muse {
    background-image: url(/static/media/muse.c2d0ac0d.jpg);
  }

  #isyimd {
    background-image: url(/static/media/ISYIMD.c9bf210c.jpg);
  }

  #moy {
    background-image: url(/static/media/moy.cecfe452.jpg);
  }

  #everything {
    background-image: url(/static/media/Everything.a30eb456.png);
  }

  #brightside {
    background-image: url(/static/media/bright2.677a7f44.jpg);
  }

  #pcbh {
    background-image: url(/static/media/PCBH2.4b4a30e6.png);
  }

  #ata {
    background-image: url(/static/media/ata.f366e6f2.jpg);
  }

  #highwaysigns {
    background-image: url(/static/media/highwaysigns.e609d764.jpg);
  }

  #righton {
    background-image: url(/static/media/righton.a23073cd.jpg);
  }

  #imready {
    background-image: url(/static/media/imready.2f52fe99.jpg);
  }

  #lazyriver {
    background-image: url(/static/media/lazyriver.6c0280d2.jpg);
  }

  #procrastinator {
    background-image: url(/static/media/procrastinator2.dfd47b27.png);
  }

.App {
  text-align: center;
  background: url(/static/media/space-4984262_1920.c3bafd61.jpg) no-repeat center center fixed;
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.head {
  height: 70vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.5vw;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
